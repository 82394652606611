import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/__01.LAVORO_wip/ArcheoSIT/sito/index.js";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "../components/hero.js";
import * as Container from "../components/container.js";
import * as Section from "../components/splitSections.js";
import { Button } from "../components/button.js";
import Team from "../components/team.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container.Fluid id="team" className="py-5">
      <Section.One col="7" className="pe-lg-4">
        <h3>{`Il nostro Team`}</h3>
        <hr />
        <p>{`ArcheoSIT nasce da un team di `}<strong parentName="p">{`ricercatori indipendenti`}</strong>{` che hanno fondato il “Centro Studi di Archeologia Preventiva Siciliana” con sede nella città di Enna.`}</p>
        <p>{`Il progetto è stato ideato dall’archeologo Paolo D’Angelo e sviluppato insieme all’informatico Fabrizio Natanaele Severino.`}</p>
        <p><strong parentName="p">{`Collaborano`}</strong>{` con il “Centro Studi di Archeologia Preventiva Siciliana” professionisti e ricercatori provenienti da ogni parte del mondo, mettendo a disposizione mezzi e `}<strong parentName="p">{`risorse`}</strong>{` indispensabili per il censimento delle emergenze archeologiche del territorio.`}</p>
      </Section.One>
      <Section.Two col="5" className="py-4 px-5 mt-4 mt-lg-0 pt-lg-0 pb-lg-0 pt-lg-0" style={{
        "background": "#A9461D",
        "borderRadius": "8px",
        "boxShadow": "0 20px 25px -5px rgba(0,0,0,.1), 0 10px 10px -5px rgba(0,0,0,.04)"
      }}>
        <div className="py-3 py-lg-5" style={{
          color: "#ffffff"
        }}>
          <h4>{`Prendi parte alla nostra missione`}</h4>
          <p>{`Solo i risultati contano! Il “Centro Studi di Archeologia Preventiva Siciliana” è alla `}<strong parentName="p">{`ricerca`}</strong>{` di archeologi, topografi, storici, geologi, ingegneri, giornalisti ed altre figure professionali.`}</p>
          <Button solid large inline targetBlank color={"#2C7784"} hrefJSON={"cta_team"} title="Contattaci per unirti a noi" mdxType="Button">
Contattaci
          </Button>
        </div>
      </Section.Two>
      <div className="row pt-5 pb-0 px-3">
        <Team mdxType="Team" />
      </div>
    </Container.Fluid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      